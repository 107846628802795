import { Controller } from "@hotwired/stimulus"
import Slider from "../src/slider"

export default class AvailablePackagesController extends Controller {
  static targets = ["paragraph", "slide", "bundlePackage", "endDatePackage", "endDateBtn"]
  static values = { bundleGap: Number, endDateGap: Number }

  connect() {
    this.initialSlider()
    this.showCardTag()
  }

  checkRadioBtn(event) {
    let selectedCard = event.currentTarget
    let radio = selectedCard.querySelector("input[type='radio']")
    radio.checked = true
  }

  initialSlider() {
    if (this.hasBundlePackageTarget) {
      const bundleGap = window.innerWidth < 768 ? 24 : 36
      const bundlePackage = new Slider(this.bundlePackageTarget, {
        gap: bundleGap,
        highlightOnClick: true,
        scaleOnClick: 1.1,
      })
      bundlePackage.init()
    }

    if (this.hasEndDatePackageTarget) {
      const endDateGap = window.innerWidth < 768 ? 22 : 28
      const endDatePackage = new Slider(this.endDatePackageTarget, {
        gap: endDateGap,
        scaleOnClick: 1.15
      })
      endDatePackage.init()
    }
  }

  handleSelectedEndDatePackage(event) {
    const bundlePackageId = event.currentTarget.dataset.bundlePackageId
    const input = this.endDateBtnTarget.parentElement.querySelector("input[name='bundle_package_id']")
    input.value = bundlePackageId
  }

  showCardTag() {
    this.slideTargets.forEach(card => {
      let tagElement = card.querySelector(".tag")

      switch (card.dataset.tag) {
        case "best_selling":
          tagElement.innerText = "ขายดี"
          break

        case "recommended":
          tagElement.innerText = "แนะนำ"
          break

        case "popular":
          tagElement.innerText = "ยอดนิยม"
          break
      }
    })
  }

  toggleParagraph() {
    this.paragraphTarget.classList.toggle("show")
  }
}
