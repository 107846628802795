export default class Slider {
  constructor(slideWrapper, options = {}) {
    this.minimum = 2
    this.currentTouchX = 0
    this.isAnimateEnd = true

    this.slideWrapper = slideWrapper
    this.gap = options.gap || 0
    this.highlightOnClick = options.highlightOnClick || false
    this.scaleOnClick = options.scaleOnClick || 1
  }

  get #scale() {
    return this.scaleOnClick
  }

  get #oneSlideStep() {
    return this.#slideWidth + this.gap
  }

  get #shouldHighlight() {
    return this.highlightOnClick
  }

  get #slideItems() {
    return [...this.slideWrapper.querySelectorAll(".slide")]
  }

  get #slideWidth() {
    return this.#slideItems[0].offsetWidth
  }

  get #slideWrapperWidth() {
    return this.slideWrapper.offsetWidth
  }

  get #startSlideStep() {
    return -((this.minimum * (this.#slideWidth + this.gap)) - (this.#slideWrapperWidth / 2) + (this.#slideWidth / 2))
  }

  get #totalSlide() {
    return this.slideWrapper.querySelectorAll(".slide").length
  }

  #addCloneSlides() {
    const requiredFirstSlide = this.#slideItems.slice(0, this.#totalSlide - this.minimum)
    const requiredLastSlide = this.#slideItems.slice(this.#totalSlide - this.minimum).reverse()

    requiredFirstSlide.forEach(slide => {
      let cloneSlide = slide.cloneNode(true)
      cloneSlide.dataset.slideIndex = this.#slideItems.length
      this.slideWrapper.append(cloneSlide)
    })

    requiredLastSlide.forEach((slide, index) => {
      let cloneSlide = slide.cloneNode(true)
      cloneSlide.dataset.slideIndex = (this.#totalSlide * 2 - 1) - index
      this.slideWrapper.prepend(cloneSlide)
    })
  }

  #assignEventForSlide() {
    this.#slideItems.forEach(slide => {
      slide.addEventListener("click", (e) => {
        this.#moveSlideToCenter(e)
        this.#scaleUpSlide(e)
      })
    })
  }

  #assignEventsForSlideWrapper() {
    this.slideWrapper.addEventListener("touchstart", (e) => {
      this.currentTouchX = e.touches[0].clientX

      this.slideWrapper.addEventListener("touchmove", (e) => {
        this.#handleSlideMove(e.touches[0].clientX)
      })
    })

    this.slideWrapper.addEventListener("mousedown", (e) => {
      this.currentTouchX = e.clientX
    })

    this.slideWrapper.addEventListener("mouseup", (e) => {
      this.#handleSlideMove(e.clientX)
    })
  }

  #handleSlideMove(newTouchX) {
    let differentTouchX = this.currentTouchX - newTouchX
    let slideToLeft = differentTouchX > 0
    let slideToRight = differentTouchX < 0

    if (slideToLeft) {
      this.#handleWhenSlideToLeft()
    } else if (slideToRight) {
      this.#handleWhenSlideToRight()
    }
  }

  #handleWhenSlideToLeft() {
    if (!this.isAnimateEnd) return

    this.isAnimateEnd = false
    this.#setTransform(this.#startSlideStep - this.#oneSlideStep, 300)

    setTimeout(() => {
      const firstSlide = this.slideWrapper.firstElementChild
      this.slideWrapper.firstElementChild.remove()
      this.slideWrapper.append(firstSlide)
      this.#setTransform(this.#startSlideStep, 0)
      this.#setAnimateEnd()
    }, 300)
  }

  #handleWhenSlideToRight() {
    if (!this.isAnimateEnd) return

    this.isAnimateEnd = false
    this.#setTransform(this.#startSlideStep + this.#oneSlideStep, 300)

    setTimeout(() => {
      const lastSlide = this.slideWrapper.lastElementChild
      this.slideWrapper.lastElementChild.remove()
      this.slideWrapper.prepend(lastSlide)
      this.#setTransform(this.#startSlideStep, 0)
      this.#setAnimateEnd()
    }, 300)
  }

  #moveSlideToCenter(event) {
    const clickPoint = event.clientX
    const remainingAreaSide = (window.innerWidth - this.#slideWidth - (2 * this.gap)) / 2
    const leftArea = remainingAreaSide
    const rightArea = remainingAreaSide + this.#slideWidth + (2 * this.gap)

    if (clickPoint > rightArea) {
      this.#handleWhenSlideToLeft()
    } 

    if (clickPoint < leftArea) {
      this.#handleWhenSlideToRight()
    }
  }

  #scaleDownAllSlide() {
      document.querySelectorAll(".slide").forEach(card => {
      const translate = card.style.transform.split(" ")[0]
      card.style.transform = `${translate}`
    })
  }

  #scaleUpSlide(event) {
    this.#scaleDownAllSlide()
    const card = event.currentTarget
    const currentValue = card.style.transform
    card.style.transitionDuration = "300ms"
    card.style.transform = `${currentValue} scale(${this.#scale})`
  }

  #setAnimateEnd() {
    this.isAnimateEnd = true
  }

  #setSlideGap() {
    this.slideWrapper.style.columnGap = `${this.gap}px`
  }

  #setSlideIndexAttribute() {
    this.#slideItems.forEach((slide, index) => {
      slide.dataset.slideIndex = index
    })
  }

  #setTransform(translate, duration) {
    this.#slideItems.forEach(slide => {
      const scale = slide.style.transform.split(" ")[1] ? slide.style.transform.split(" ")[1] : ""
      slide.style.transform = `translateX(${translate}px) ${scale}`
      slide.style.transitionDuration = `${duration}ms`
    })
  }

  #showDefaultSelectedSlide() {
    const activeCard = this.slideWrapper.querySelector(".slide[data-slide-index='0']")

    if (this.#shouldHighlight) {
      const radio = activeCard.querySelector("input[type='radio']")
      radio.checked = true

      const translate = activeCard.style.transform
      activeCard.style.transform = `${translate} scale(${this.#scale})`
    }
  }

  init() {
    this.#setSlideGap()
    this.#setSlideIndexAttribute()
    this.#addCloneSlides()
    this.#setTransform(this.#startSlideStep, 0)
    this.#showDefaultSelectedSlide()
    this.#assignEventsForSlideWrapper()
    this.#assignEventForSlide()
  }
}